
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

// for higher specificity
.v-card.company-card-light-color.company-card-light-color {
    background-color: transparent !important;

    * {
        color: $light-black !important;
    }
}
